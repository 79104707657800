import { render, staticRenderFns } from "./Branding.vue?vue&type=template&id=e4c324e8&scoped=true"
import script from "./Branding.vue?vue&type=script&lang=js"
export * from "./Branding.vue?vue&type=script&lang=js"
import style0 from "./Branding.vue?vue&type=style&index=0&id=e4c324e8&prod&lang=scss"
import style1 from "./Branding.vue?vue&type=style&index=1&id=e4c324e8&prod&scoped=true&lang=css"
import style2 from "./Branding.vue?vue&type=style&index=2&id=e4c324e8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4c324e8",
  null
  
)

export default component.exports