<template>
  <div>
    <div class="logo-sty">
      <img
        v-loading="loading"
        v-if="this.imgData"
        :src="this.imgData"
        height="100"
        width="200"
        class="avatar mt-1"
      />

      <img v-loading="loading" v-else :src="logo" height="100" width="200" class="avatar mt-1" />
    </div>
    <div class="main-sty" style="height:300px; width:720px;">
      <div class="card-sty" style="border: 1px solid #1b487e;margin-top:10px;">
        <div>
          <p
            class="pr-sty"
            style="margin-top:27px;"
          >You are requested by {Company Name} to sign the document</p>
        </div>

        <div class="btn-style">
          <el-button type="danger">Review & Sign</el-button>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="para-div-sty">
        <!-- <el-divider></el-divider> -->
        <p
          class="para-sty"
        >eSigns is an all-in-one document automation solution with advanced capabilities, simple and easy to use for teams of all sizes. It is an application that creates, sends, signs, and views documents in a fast, secure, and easy way.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["imgData", "logo"],
  computed: {
    ...mapGetters("company", ["fetchCompanyBySlug", "getSingleCompanyLogo"])
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
<style scoped>
.text-sty {
  color: white;
}
.card-sty {
  background-color: #1b487e;
  height: 130px;
  width: 700px;
  align-items: center;
  margin-left: 10px;
}
.para-div-sty {
  margin-top: 25px;
  margin-left: 10px;
  height: 80px;
  width: 700px;
  align-items: center;
}
.main-sty {
  margin-top: 8px;
  border: 1px solid rgb(217, 216, 216);
  /* margin-left:60px;  */
}
.para-sty {
  justify-content: center;
}
.pr-sty {
  color: white;
  margin-left: 150px;
}
.btn-style {
  margin-left: 270px;
  margin-top: 15px;
}
</style>